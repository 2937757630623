import React from "react";

// mui-material
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// third-party
import PropTypes from "prop-types";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import { drawerWidth } from "themes/constants";
import MenuList from "layout/MainLayout/Sidebar/MenuList";

// style constant
const PREFIX = "Sidebar";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
};

const Root = styled("nav")(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      top: "100px",
      height: "calc(100% - 100px)",
    },
  },
}));

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const drawer = (
    <React.Fragment>
      <BrowserView>
        <MenuList />
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </MobileView>
    </React.Fragment>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root className={classes.drawer} aria-label="main menu">
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Root>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
