import React, { lazy } from "react";

// third-party library
import { Navigate, Route, Routes } from "react-router";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { systemModules } from "system_modules";
import { systemRoles } from "system_security";
import { getInitialRoute } from "common";

const LazyChangePassword = Loadable(
  lazy(() => import("../views/authentication/change-password/ChangePassword"))
);

const LazyUserPreferences = Loadable(
  lazy(() => import("../views/app-components/UserPreferences"))
  //lazy(() => import("../views/application/users/account-profile/Profile1"))
);

const LazyAdmin = Loadable(
  lazy(() => import("../views/app-components/Admin/AdminPage"))
);
const LazyCreateEditAdmin = Loadable(
  lazy(() => import("../views/app-components/Admin/CreateEditAdmin"))
);

const LazyClubCignallMembersHomePage = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallMembers/ClubCignallMembersHomePage"
      )
  )
);
const LazyClubCignallMembersAddPage = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallMembers/ClubCignallMembersAddPage"
      )
  )
);
const LazyCategories = Loadable(
  lazy(() => import("../views/app-components/Categories"))
);
const LazyCategoriesAddEdit = Loadable(
  lazy(() => import("../views/app-components/Categories/CategoriesAddEdit"))
);

const LazyProducts = Loadable(
  lazy(() => import("../views/app-components/Products"))
);
const LazyProductAddEdit = Loadable(
  lazy(() => import("../views/app-components/Products/ProductAddEdit"))
);
const LazyBrandAddEdit = Loadable(
  lazy(() => import("../views/app-components/Brands/BrandAddEdit"))
);

const LazyFranchisee = Loadable(
  lazy(() => import("../views/app-components/Franchisee"))
);

const LazyFranchiseeAddEdit = Loadable(
  lazy(() => import("../views/app-components/Franchisee/FranchiseeAddEdit"))
);

const LazyStores = Loadable(
  lazy(() => import("../views/app-components/Stores"))
);

const LazyStoresAddEdit = Loadable(
  lazy(() => import("../views/app-components/Stores/StoreAddEdit"))
);

const LazyBanners = Loadable(
  lazy(() => import("../views/app-components/Banners"))
);

const LazyBannersAddEdit = Loadable(
  lazy(() => import("../views/app-components/Banners/BannersAddEdit"))
);
// regions related components

const LazyRegions = Loadable(
  lazy(() => import("../views/app-components/Regions"))
);

const LazyRegionStores = Loadable(
  lazy(() => import("../views/app-components/Regions"))
);

const LazyRegionsAddEdit = Loadable(
  lazy(() => import("../views/app-components/Regions/AddEdit"))
);

const LazyContactFormList = Loadable(
  lazy(() => import("../views/app-components/ContactForm"))
);

const LazyContactFormDetails = Loadable(
  lazy(() => import("../views/app-components/ContactForm/ContactFormDetails"))
);

const LazyFranchiseeContactFormDetails = Loadable(
  lazy(
    () =>
      import("../views/app-components/ContactForm/FranchiseeContactFormDetails")
  )
);

const LazyFranchiseeNews = Loadable(
  lazy(() => import("../views/app-components/FranchiseeNews"))
);

const LazyFranchiseeNewsAddEdit = Loadable(
  lazy(
    () =>
      import("../views/app-components/FranchiseeNews/FranchiseeNewsCreateEdit")
  )
);

const LazyNewsAcknowledgementList = Loadable(
  lazy(
    () => import("../views/app-components/FranchiseeNews/AcknowledgementList")
  )
);

// franchisee post

const LazyFranchiseePost = Loadable(
  lazy(() => import("../views/app-components/FranchiseePosts"))
);

const LazyFranchiseePostAddEdit = Loadable(
  lazy(() => import("../views/app-components/FranchiseePosts/AddEdit"))
);

const LazyMemberNotifications = Loadable(
  lazy(() => import("../views/app-components/MemberNotifications"))
);
const LazyMemberNotificationAddEdit = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/MemberNotifications/MemberNotificationAddEdit"
      )
  )
);

const LazyClubCignallMemberPrize = Loadable(
  lazy(() => import("../views/app-components/ClubCignallPrizeMailer"))
);

const LazyClubCignallMemberPrizeAdd = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallPrizeMailer/MailMemberPrizeAdd"
      )
  )
);
const LazyClubCignallMemberEmailPreview = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallPrizeMailer/components/EmailPreview"
      )
  )
);
const LazyPostAcknowledgementList = Loadable(
  lazy(
    () => import("../views/app-components/FranchiseePosts/AcknowledgementList")
  )
);

//member transfer routing
const LazyClubCignallMemberTransfer = Loadable(
  lazy(() => import("../views/app-components/ClubCignallMemberTransfer"))
);

const LazyClubCignallMemberTransferAddRequest = Loadable(
  lazy(
    () =>
      import("../views/app-components/ClubCignallMemberTransfer/TransferMember")
  )
);

const LazyClubCignallMemberTransferEditRequest = Loadable(
  lazy(
    () =>
      import(
        "../views/app-components/ClubCignallMemberTransfer/EditMemberTransferRequest"
      )
  )
);

//settings page routing
const LazySystemSettings = Loadable(
  lazy(() => import("../views/app-components/Settings/SystemSettings"))
);
const LazyNotificationTemplates = Loadable(
  lazy(() => import("../views/app-components/Settings/NotificationTemplates"))
);

//BDR page routing
const LazyBDRFranchiseePost = Loadable(
  lazy(() => import("../views/app-components/BDR/FranchiseePosts"))
);

const LazyBDRFranchiseePostView = Loadable(
  lazy(
    () =>
      import("../views/app-components/BDR/FranchiseePosts/FranchiseePostView")
  )
);

const LazyBDRFranchiseeNews = Loadable(
  lazy(() => import("../views/app-components/BDR/FranchiseeNews"))
);

const LazyBDRFranchiseeNewsView = Loadable(
  lazy(
    () =>
      import("../views/app-components/BDR/FranchiseeNews/FranchiseeNewsView")
  )
);

const LazyBDRStores = Loadable(
  lazy(() => import("../views/app-components/BDR/Stores"))
);

const LazyBDRStoreView = Loadable(
  lazy(() => import("../views/app-components/BDR/Stores/StoreView"))
);

const PageNotFound = () => {
  return <h1>404 - Page Not Found</h1>;
};

//-----------------------|| MAIN ROUTING ||-----------------------//

function MainRoutes({ admin }) {
  if (!admin) {
    return null;
  }
  if (admin.role === systemRoles.StoreRep) {
    return (
      <MainLayout>
        <Routes>
          {/* Franchisee News */}
          <>
            <Route path="franchisee-news">
              <Route path="" element={<LazyBDRFranchiseeNews />} />
              <Route path="view/:id" element={<LazyBDRFranchiseeNewsView />} />
            </Route>
            <Route path="franchisee-posts">
              <Route path="" element={<LazyBDRFranchiseePost />} />
              <Route path="view/:id" element={<LazyBDRFranchiseePostView />} />
            </Route>
            <Route path="stores">
              <Route path="" element={<LazyBDRStores />} />
              <Route path="view/:id" element={<LazyBDRStoreView />} />
            </Route>
            <Route path="user-preferences" element={<LazyUserPreferences />} />
            <Route path="change-password" element={<LazyChangePassword />} />
            <Route
              path="/"
              element={<Navigate to={getInitialRoute(admin.role)} replace />}
            />
            <Route path="*" element={<PageNotFound />} />
          </>
        </Routes>
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <Routes>
        {/* </AuthGuard> */}

        {/* Club Cignall Member access */}
        {admin && systemModules.HasAccessToClubCignallMembers(admin.modules) ? (
          <>
            <Route path="clubcignallmembers">
              <Route path="" element={<LazyClubCignallMembersHomePage />} />
              <Route path="add" element={<LazyClubCignallMembersAddPage />} />
              <Route
                path="edit/:memberId"
                element={<LazyClubCignallMembersAddPage />}
              />
            </Route>
          </>
        ) : null}

        {/* Club Cignall Member Transfer */}
        {admin && systemModules.HasAccessToMembersTransfer(admin.modules) ? (
          <>
            <Route path="member-transfer">
              <Route path="" element={<LazyClubCignallMemberTransfer />} />
              <Route
                path="add"
                element={<LazyClubCignallMemberTransferAddRequest />}
              />
              <Route
                path="edit/:id"
                element={<LazyClubCignallMemberTransferEditRequest />}
              />
            </Route>
          </>
        ) : null}

        {/* Franchisee access */}
        {admin && systemModules.HasAccessToFranchisee(admin.modules) ? (
          <>
            <Route path="franchisees">
              <Route path="" element={<LazyFranchisee />} />
              <Route path="add" element={<LazyFranchiseeAddEdit />} />
              <Route
                path="edit/:franchiseeId"
                element={<LazyFranchiseeAddEdit />}
              />
            </Route>
          </>
        ) : null}
        {/* Store access */}
        {admin && systemModules.HasAccessToStores(admin.modules) ? (
          <>
            <Route path="stores">
              <Route path="" element={<LazyStores />} />
              <Route path="add" element={<LazyStoresAddEdit />} />
              <Route path="edit/:storeId" element={<LazyStoresAddEdit />} />
            </Route>
          </>
        ) : null}
        {/* Region access */}
        {admin && systemModules.HasAccessToRegions(admin.modules) ? (
          <>
            <Route path="regions">
              <Route path="" element={<LazyRegions />} />
              <Route path="add" element={<LazyRegionsAddEdit />} />
              <Route path="edit/:regionId" element={<LazyRegionsAddEdit />} />
              <Route path="stores/:regionId" element={<LazyRegionStores />} />
            </Route>
          </>
        ) : null}
        {/* Categories access */}
        {admin && systemModules.HasAccessToCategories(admin.modules) ? (
          <>
            <Route path="categories">
              <Route path="" element={<LazyCategories />} />
              <Route path="add" element={<LazyCategoriesAddEdit />} />
              <Route
                path="edit/:categoryId"
                element={<LazyCategoriesAddEdit />}
              />
            </Route>
          </>
        ) : null}
        {/* Brands access */}
        {admin && systemModules.HasAccessToBrands(admin.modules) ? (
          <>
            <Route path="brand/add" element={<LazyBrandAddEdit />} />
          </>
        ) : null}
        {/* Products access */}
        {admin && systemModules.HasAccessToProducts(admin.modules) ? (
          <>
            <Route path="products">
              <Route path="" element={<LazyProducts />} />
              <Route path="add" element={<LazyProductAddEdit />} />
              <Route path="edit/:productId" element={<LazyProductAddEdit />} />
            </Route>
          </>
        ) : null}
        {/* Banners access */}
        {admin && systemModules.HasAccessToBanners(admin.modules) ? (
          <>
            <Route path="banners">
              <Route path="" element={<LazyBanners />} />
              <Route path="add" element={<LazyBannersAddEdit />} />
              <Route path="edit/:bannerId" element={<LazyBannersAddEdit />} />
            </Route>
          </>
        ) : null}
        {/* Franchisee News */}
        {admin && systemModules.HasAccessToFranchiseeNews(admin.modules) ? (
          <>
            <Route path="franchisee-news">
              <Route path="" element={<LazyFranchiseeNews />} />
              <Route path="add" element={<LazyFranchiseeNewsAddEdit />} />
              <Route
                path="edit/:franchiseeNewsId"
                element={<LazyFranchiseeNewsAddEdit />}
              />
            </Route>
            <Route
              path="news-acknowledgements"
              element={<LazyNewsAcknowledgementList />}
            />
          </>
        ) : null}
        {/* Franchisee Post */}
        {admin && systemModules.HasAccessToFranchiseePosts(admin.modules) ? (
          <>
            <Route path="franchisee-posts">
              <Route path="" element={<LazyFranchiseePost />} />
              <Route path="add" element={<LazyFranchiseePostAddEdit />} />
              <Route path="edit/:id" element={<LazyFranchiseePostAddEdit />} />
            </Route>
            <Route
              path="post-acknowledgements"
              element={<LazyPostAcknowledgementList />}
            />
          </>
        ) : null}
        {/* Member Notifications */}
        {admin &&
        systemModules.HasAccessToMemberNotifications(admin.modules) ? (
          <>
            <Route path="member-notification">
              <Route path="" element={<LazyMemberNotifications />} />
              <Route path="add" element={<LazyMemberNotificationAddEdit />} />
              <Route
                path="edit/:notificationId"
                element={<LazyMemberNotificationAddEdit />}
              />
            </Route>
          </>
        ) : null}
        {/*Club Cignall Prizes */}
        {admin && systemModules.HasAccessToClubCignallPrizes(admin.modules) ? (
          <>
            <Route path="club-cignall-prize">
              <Route path="" element={<LazyClubCignallMemberPrize />} />
              <Route path="add" element={<LazyClubCignallMemberPrizeAdd />} />
              <Route
                path="preview/:clubCignallPrizeId"
                element={<LazyClubCignallMemberEmailPreview />}
              />
            </Route>
          </>
        ) : null}
        {/* Contact Forms */}
        {admin && systemModules.HasAccessToContactForms(admin.modules) ? (
          <>
            <Route path="contacts">
              <Route path="" element={<LazyContactFormList />} />
              <Route
                path="general/:contactFormId"
                element={<LazyContactFormDetails />}
              />
              <Route
                path="franchisee/:franchiseeContactFormId"
                element={<LazyFranchiseeContactFormDetails />}
              />
            </Route>
          </>
        ) : null}
        {/* Admins access */}
        {admin && systemModules.HasAccessToAdmin(admin.modules) ? (
          <>
            <Route path="admins">
              <Route path="" element={<LazyAdmin />} />
              <Route path="add" element={<LazyCreateEditAdmin />} />
              <Route path="edit/:adminId" element={<LazyCreateEditAdmin />} />
            </Route>
          </>
        ) : null}
        {/* Settings */}
        {admin && systemModules.HasAccessToSettings(admin.modules) ? (
          <>
            <Route path="system-settings" element={<LazySystemSettings />} />
            <Route
              path="notification-templates"
              element={<LazyNotificationTemplates />}
            />
          </>
        ) : null}
        <Route path="user-preferences" element={<LazyUserPreferences />} />
        <Route path="change-password" element={<LazyChangePassword />} />
        <Route
          path="login"
          element={<Navigate to={getInitialRoute(admin.role)} replace />}
        />
        <Route
          path="/"
          element={<Navigate to={getInitialRoute(admin.role)} replace />}
        />
        <Route path="*" element={<PageNotFound />} />
        {/* </AuthGuard> */}
      </Routes>
    </MainLayout>
  );
}

export default MainRoutes;
